import React from 'react';
import { Main } from '../parts/main';
import { Widgets, WidgetsProps } from '../widgets';
import { graphql } from 'gatsby';
import { WorkSlice } from '../../../gatsby-node';
import { WorkQueryQuery } from '../../../types/gatsby-types';
import { useLayoutTheme } from '../layout/layout';
import { WorkContext } from './work.context';
import { SiteMetaContext } from '../site-meta';
import { Seo } from '../Seo/seo';
import { getThemeOrDefault, selectTheme } from '../../helpers/theme-helpers';

export interface NextProject {
  url: string;
  title: string;
}

export interface INextWork {
  next?: NextProject;
}

/**
 * Come from Gatsby, see gatsby-node.js
 */
export interface WorkTemplateProps {
  data: WorkQueryQuery;
  pageContext: WorkSlice & INextWork;
}

export const WorkFragment = graphql`
  fragment WorkFragment on Nmbl_Work {
    contentItemId
    alias {
      alias
    }
    headerTheme {
      theme
    }
    footerTheme {
      theme
    }
    highlightColor
    seo {
      ...SeoFragment
    }
    displayText
    featuredImage {
      ...ImageFragment
    }
    bag {
      contentItems {
        ...WidgetFragment
      }
    }
  }
`;

const WorkTemplate: React.FC<WorkTemplateProps> = ({ data, pageContext }) => {
  const work = data.nmbl.work && data.nmbl.work[0];
  const siteMeta = React.useContext(SiteMetaContext);
  const workPage = data.nmbl.page?.find(
    page => page?.contentItemId === siteMeta?.pageSettings?.workPageId
  );

  if (!work || !workPage) {
    throw new Error('Could not load work template.');
  }

  const footerTheme = getThemeOrDefault(work.footerTheme?.theme, 'dark');

  useLayoutTheme(
    getThemeOrDefault(work.headerTheme?.theme, 'dark'),
    footerTheme
  );

  const widgetsWithNextProject: WidgetsProps['widgets'] = [
    ...(work.bag?.contentItems ?? []),
    pageContext.next
      ? {
          contentType: 'NextProjectWidget',
          __typename: 'Nmbl_NextProjectWidget',
          next: pageContext.next,
          theme: { theme: footerTheme },
        }
      : undefined,
  ];

  return (
    <WorkContext.Provider
      value={{
        work,
        workPage,
      }}
    >
      <Main template="work">
        <Seo
          {...work.seo}
          title={work.seo?.title || work.displayText}
          path={pageContext.pagePath}
        />
        <Widgets widgets={widgetsWithNextProject} />
      </Main>
    </WorkContext.Provider>
  );
};

export default WorkTemplate;

export const pageQuery = graphql`
  query WorkQuery($slug: String!) {
    nmbl {
      work(where: { alias: { alias: $slug } }) {
        ...WorkFragment
      }
      page {
        ...PageFragment
      }
    }
  }
`;
